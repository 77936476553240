import React, { createContext, useContext, useState } from 'react'

const NotificationContext = createContext()

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }) => {
  const [message, setMessage] = useState(null)
  const [type, setType] = useState('info')
  const [open, setOpen] = useState(false)

  const showNotification = (message, type = 'info') => {
    setMessage(message)
    setType(type)
    setOpen(true)
    setTimeout(() => setOpen(false), 3000) // Hide after 3 seconds
  }

  const hideNotification = () => {
    setOpen(false)
  }

  return (
    <NotificationContext.Provider
      value={{ message, type, open, showNotification, hideNotification }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
