import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewCommunicationModal = ({
  selectedCommunication,
  setSelectedCommunication,
  communications
}) => {
  return (
    <Modal
      open={!!selectedCommunication}
      onClose={() => setSelectedCommunication(null)}
      aria-labelledby="Communication Modal"
      aria-describedby="This modal contains the detailed information regarding the communications"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Communication Details
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedCommunication?.resource?.meta?.lastUpdated).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        <Typography>
          Message:{' '}
          {selectedCommunication?.resource?.payload[0]?.contentString || 'NA'}
        </Typography>
        <Typography>
          Date:{' '}
          {`${moment(selectedCommunication?.resource?.sent).format('MMMM Do YYYY, h:mm:ss a')}`}{' '}
        </Typography>
        <Typography>
          From: {selectedCommunication?.resource?.sender?.reference || 'NA'}
        </Typography>
        <Typography>
          To:{' '}
          {selectedCommunication?.resource?.recipient
            ?.map((rec) => rec.reference)
            .join(', ') || 'NA'}{' '}
        </Typography>
      </Box>
    </Modal>
  )
}

ViewCommunicationModal.propTypes = {
  selectedCommunication: PropTypes.object,
  setSelectedCommunication: PropTypes.func,
  communications: PropTypes.array
}

export default ViewCommunicationModal
