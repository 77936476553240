import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewAlertModal = ({ selectedAlert, setSelectedAlert }) => {
  console.log('selectedAlert', selectedAlert)
  return (
    <Modal
      open={!!selectedAlert}
      onClose={() => setSelectedAlert(null)}
      aria-labelledby="Alert Modal"
      aria-describedby="This modal contains the detailed information regarding the currently selected alert"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Alert Details
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedAlert?.resource?.identifiedDateTime).format('MMMM Do YYYY, h:mm:ss a')}`}
        </Typography>
        <hr />
        <Typography sx={{ width: '90%' }}>
          Alert details: {`${selectedAlert?.resource?.detail}`}
        </Typography>
        <Typography sx={{ width: '90%' }}>
          Status: {`${selectedAlert?.resource?.status}`}
        </Typography>
        <Typography sx={{ width: '90%' }}>
          Patient Reference: {`${selectedAlert?.resource?.patient?.reference}`}
        </Typography>
        {selectedAlert?.resource?.implicated?.length > 0 && (
          <Typography sx={{ width: '90%' }}>
            Implicated:{' '}
            {`${selectedAlert?.resource?.implicated?.[0]?.reference}`}
          </Typography>
        )}
      </Box>
    </Modal>
  )
}

ViewAlertModal.propTypes = {
  selectedAlert: PropTypes.object,
  setSelectedAlert: PropTypes.func
}

export default ViewAlertModal
