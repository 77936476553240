import React, { createContext, useCallback, useEffect, useState } from 'react'
import ClinicvuService from '../services/Clinicvu/Clinicvu.service'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'

export const UserContext = createContext(null)

export const UserContextProvider = (props) => {
  const [me, setMe] = useState(null)
  const [organizations, setOrganizations] = useState(null)

  const fetchMe = useCallback(async () => {
    ClinicvuService.fetchMe().then((response) => {
      if (response.data) {
        if (process.env.REACT_APP_environment_type === 'clinicvu') {
          setMe(response.data.practitioner)
          setOrganizations(response.data.organizations)
        } else {
          setMe(response.data)
        }
      } else {
        Auth.signOut()
      }
    })
  }, [])

  useEffect(() => {
    fetchMe()
  }, [])

  return (
    <UserContext.Provider value={{ me, organizations, fetchMe }}>
      {props.children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.object
}
