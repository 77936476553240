import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn
} from '@aws-amplify/ui-react'
import { jwtContext } from '@context/jwtContext'
import { CurrentOrganizationContextProvider } from '@context/CurrentOrganization'
import { UserContextProvider } from '@context/UserContext'
import Amplify, { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import awsconfig from './aws-exports'
import Button from '@mui/material/Button'
import AppRouter from './routes/AppRouter'
import './App.scss'
import NotificationComponet from '@shared/notification/Notification'
import { NotificationProvider } from '@context/NotificationContext'
import { useLocation } from 'react-router-dom'
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'

Amplify.configure(awsconfig)

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const AuthStateApp = () => {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()
  const [jwt, setJwt] = useState()
  const [sessionSet, setSessionSet] = useState(false) // New state to track session setting
  const query = useQuery()

  useEffect(() => {
    const setAuthSession = async () => {
      const accessToken = query.get('access_token')
      const idToken = query.get('id_token')
      const refreshToken = query.get('refresh_token')

      // Ensure tokens are available and prevent repeated setting
      if (accessToken && idToken && refreshToken && !sessionSet) {
        console.log('accessToken', accessToken)
        console.log('idToken', idToken)
        console.log('refreshToken', refreshToken)

        // Create a CognitoUser
        const userPool = new CognitoUserPool({
          UserPoolId: awsconfig.aws_user_pools_id, // Replace with your User Pool ID
          ClientId: awsconfig.aws_user_pools_web_client_id // Replace with your App Client ID
        })

        const cognitoUser = new CognitoUser({
          Username: 'dummy', // Use a dummy username or the actual one if available
          Pool: userPool
        })

        // Create session tokens
        const cognitoIdToken = new CognitoIdToken({ IdToken: idToken })
        const cognitoAccessToken = new CognitoAccessToken({
          AccessToken: accessToken
        })
        const cognitoRefreshToken = new CognitoRefreshToken({
          RefreshToken: refreshToken
        })

        const session = new CognitoUserSession({
          IdToken: cognitoIdToken,
          AccessToken: cognitoAccessToken,
          RefreshToken: cognitoRefreshToken
        })

        // Set the session
        cognitoUser.setSignInUserSession(session)

        // Set user and session into Amplify's Auth
        Auth.user = cognitoUser

        console.log('cognitoUser', cognitoUser)

        // Mark session as set
        setSessionSet(true)

        // // After successful authentication, remove the specific tokens from the URL
        // const params = new URLSearchParams(window.location.search)
        // // Remove the tokens from the URL parameters
        // params.delete('access_token')
        // params.delete('id_token')
        // params.delete('refresh_token')

        // // Update the URL without reloading the page
        // window.history.replaceState(
        //   {},
        //   document.title,
        //   `${window.location.pathname}?${params.toString()}`
        // )
      }
    }
    setAuthSession() // Call the async function
  }, [query, sessionSet])

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log('nextAuthState', 'authData')
      setUser(authData)
      setAuthState(nextAuthState)
    })
  }, [])

  useEffect(() => {
    Auth.currentSession().then((res) => {
      console.log('Auth.currentSession()', res)
      const getIdToken = res.getIdToken()
      const jwt = getIdToken.getJwtToken()
      setUser(res)
      setJwt(jwt)
    })
  }, [authState])

  return (
    <NotificationProvider>
      <NotificationComponet />
      {user && jwt
        ? (
        <AmplifyAuthenticator>
          <UserContextProvider>
            <jwtContext.Provider value={jwt}>
              <CurrentOrganizationContextProvider>
                <AppRouter user={user} jwt={jwt} />
              </CurrentOrganizationContextProvider>
            </jwtContext.Provider>
          </UserContextProvider>
        </AmplifyAuthenticator>
          )
        : (
        <>
          <AmplifyAuthContainer>
            <AmplifyAuthenticator>
              <div className="homePage" slot="sign-in">
                <div className="leftPage">
                  <p>&nbsp;</p>
                  <div className="logos">
                    {process.env.REACT_APP_environment_type === 'clinicvu' && (
                      <img src="/assets/clinicvu-logo.svg" alt="Logo" />
                    )}
                    {process.env.REACT_APP_environment_type === 'biovu' && (
                      <img src="/assets/biovu-logo.svg" alt="Logo" />
                    )}
                    <img
                      src="/assets/virtual-care-logo.svg"
                      alt="Virtual Care Reimagined"
                    />
                  </div>
                  <p style={{ color: '#8a8b8c', marginBottom: 10 }}>
                    © {new Date().getFullYear()} TeleVU Innovation Ltd. - All
                    Rights Reserved.
                  </p>
                </div>
                <div className="rightPage">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      style={{
                        color: 'white',
                        marginRight: 30
                      }}
                      variant="text"
                    >
                      English
                    </Button>
                    <Button
                      style={{
                        color: 'white',
                        marginRight: 10
                      }}
                      variant="text"
                    >
                      French
                    </Button>
                  </div>
                  <AmplifySignIn
                    slot="sign-in"
                    hideSignUp={true}
                    headerText="Sign In"
                    submitButtonText="Login"
                  />
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      style={{ color: '#193139', marginRight: 30 }}
                      variant="text"
                      href="https://televu.ca/terms-of-use/"
                    >
                      Terms and Conditions
                    </Button>
                    <Button
                      style={{ color: '#193139' }}
                      variant="text"
                      href="https://televu.ca/privacy-policy/"
                    >
                      Privacy Policy
                    </Button>
                  </div>
                </div>
              </div>
            </AmplifyAuthenticator>
          </AmplifyAuthContainer>
        </>
          )}
    </NotificationProvider>
  )
}

export default AuthStateApp
