import React, { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'

export const CurrentOrganizationContext = createContext(null)

export const CurrentOrganizationContextProvider = (props) => {
  const [currentOrganization, setCurrentOrganization] = useState(0)

  const handleOrganizationChange = (event) => {
    setCurrentOrganization(event.target.value)
    const cookies = new Cookies()
    cookies.set('lastOrganization', event.target.value, {
      path: '/',
      maxAge: 1000 * 60 * 60 * 24 * 365 // 365 days
    })
  }

  const loadOrganizationCookie = () => {
    const cookies = new Cookies()
    const lastOrganization = cookies.get('lastOrganization')
    if (lastOrganization) {
      setCurrentOrganization(lastOrganization)
    } else {
      setCurrentOrganization(null)
    }
  }

  useEffect(() => {
    loadOrganizationCookie()
  }, [])

  return (
    <CurrentOrganizationContext.Provider
      value={{
        handleOrganizationChange,
        currentOrganization
      }}
    >
      {props.children}
    </CurrentOrganizationContext.Provider>
  )
}

CurrentOrganizationContextProvider.propTypes = {
  children: PropTypes.object
}
