import React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { UserContext } from '@context/UserContext'
import MediaServer from '@shared/media-server/MediaServer'
import NotificationPrompt from '@shared/notification-prompt/NotificationPrompt'
import PropTypes from 'prop-types'

// change chat to be context

const Session = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [mySessionId, setMySessionId] = React.useState(null)
  const [snackBarOpen, setSnackBarOpen] = React.useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const navigate = useNavigate()
  const { me } = React.useContext(UserContext)

  const goHome = () => {
    navigate('/')
  }

  const connectToCall = () => {
    const sessionId = searchParams.get('join_session_id')
    if (sessionId) {
      setMySessionId(sessionId)
      searchParams.delete('join_session_id')
      setSearchParams(searchParams)
    } else {
      goHome()
    }
  }

  React.useEffect(() => {
    connectToCall()
    return () => {
      setMySessionId(null)
    }
  }, [])

  const openSnackBar = React.useCallback(() => {
    setSnackBarOpen(true)
  }, [])

  const handleCloseSnackBar = React.useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }, [])

  return mySessionId && me
    ? (
    <>
      <MediaServer
        setSnackBarMessage={setSnackBarMessage}
        openSnackBar={openSnackBar}
        mySessionId={mySessionId}
      />
      <NotificationPrompt
        snackBarOpen={snackBarOpen}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMessage={snackBarMessage}
      />
    </>
      )
    : null
}

Session.propTypes = {
  setSnackBarMessageName: PropTypes.func,
  setSnackBarMessage: PropTypes.func,
  openSnackBar: PropTypes.func,
  online: PropTypes.object,
  addRemotevuToCall: PropTypes.func,
  addGlassvuToCall: PropTypes.func
}

export default React.memo(Session)
