import React from 'react'
import { OpenVidu } from 'openvidu-browser'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { parseSubscriber } from '../../../utils/functions'
import VideoCall from '@shared/video-call/VideoCall'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '@context/UserContext'

const MediaServer = ({ mySessionId, setSnackBarMessage, openSnackBar }) => {
  const { me } = React.useContext(UserContext)
  const [OV, setOV] = React.useState(null)
  const [session, setSession] = React.useState(null)
  const [subscribers, setSubscribers] = React.useState([])
  const [token, setToken] = React.useState(null)
  const [mainStreamManager, setMainStreamManager] = React.useState(null)
  const [messageHistory, setMessageHistory] = React.useState([])
  const [recordingMode, setRecordingMode] = React.useState(false)
  const [streamID, setStreamID] = React.useState(null)
  const navigate = useNavigate()
  let clearTimer

  React.useEffect(() => {
    const OV = new OpenVidu()

    OV.setAdvancedConfiguration({
      forceMediaReconnectionAfterNetworkDrop: true
    })

    OV.enableProdMode()

    setOV(OV)

    const session = OV.initSession()

    setSession(session)

    session.on('connectionCreated', (event) => {
      console.log(event)
      const userMetadata = JSON.parse(event.connection.data)
      const name = userMetadata.lastName
      if (name !== me.name[0]?.family) {
        setSnackBarMessage(name + 'joined call')
        openSnackBar()
      }
    })

    session.on('connectionDestroyed', (event) => {
      console.log(event)
      handleConnectionDestroyed(event)
    })

    session.on('streamCreated', (event) => {
      console.log(event)
      const subscriber = session.subscribe(event.stream, undefined)
      addSubscriber(subscriber)
    })

    session.on('streamDestroyed', (event) => {
      console.log(event)
      deleteSubscriber(event.stream.streamManager)
    })

    session.on('streamPropertyChanged', (event) => {
      console.log(event)
    })

    session.on('sessionDisconnected', (event) => {
      console.log(event)
      // setConnected(!connected)
    })

    session.on('exception', (exception) => {
      console.warn(exception)
      if (exception.name === 'NO_STREAM_PLAYING_EVENT') {
        setSnackBarMessage(
          'Failed to receive video. Try refreshing the connection'
        )
        openSnackBar()
      }
    })

    session.on('signal:message', (event) => {
      const from = JSON.parse(event.from.data).lastName
      const message = { from, message: event.data }
      addChatMessage(message)
      if (from !== me.name[0]?.family) {
        setSnackBarMessage(from + ': ' + event.data)
        openSnackBar()
      }
    })

    createToken().then((response) => {
      const token = response.token
      const connectionData = {
        firstName: me.name[0]?.given?.[0] || '',
        lastName: me.name[0]?.family || '',
        designation:
          me.extension?.find((ext) => ext.url === 'designation')?.valueString ||
          '',
        email:
          me.telecom?.find((contact) => contact.system === 'email')?.value || ''
      }

      session
        .connect(token, connectionData)
        .then(() => {
          setToken(token)
        })
        .catch((error) => {
          console.log(
            'There was an error connecting to the session:',
            error.code,
            error.message
          )
        })
    })

    return () => {
      session.disconnect()
      session.off('streamCreated')
      session.off('connectionCreated')
      session.off('connectionDestroyed')
      session.off('streamPropertyChanged')
      session.off('streamDestroyed')
      session.off('exception')
      session.off('signal:message')
      session.off('sessionDisconnected')
    }
  }, [])

  React.useEffect(() => {
    return () => {
      clearTimeout(clearTimer)
    }
  }, [])

  const leaveSession = () => {
    if (session) {
      session.disconnect()
      ClinicvuService.leaveSession(mySessionId, token)
    }
  }

  const sendSignal = async (type, content) => {
    if (session) {
      return session.signal({
        data: content,
        to: [],
        type
      })
    }
  }

  const endSession = () => {
    if (session) {
      setSnackBarMessage('Call ended.')
      openSnackBar()
      leaveSession()
    }
    goHome()
  }

  const endSessionLater = (now = false) => {
    setSnackBarMessage(
      'You are the last user in the call. Ending the call in 1 minute if no one reconnects.'
    )
    openSnackBar()
    if (now) {
      if (getNumberOfParticipants() === 0) {
        endSession()
      }
    } else {
      clearTimer = setTimeout(() => {
        endSessionLater(true)
      }, 60000)
    }
  }

  const getNumberOfParticipants = () => {
    const localSubscribers = subscribers
    let activeConnections = localSubscribers.length
    localSubscribers.forEach((sub) => {
      const userMetadata = JSON.parse(sub.stream?.connection?.data)
      const userType = userMetadata.userType
      if (userType === 'ScreenVU') {
        activeConnections = activeConnections - 1
      }
    })
    return activeConnections
  }

  const createToken = async () => {
    return await ClinicvuService.joinSession(me.email, mySessionId)
      .then((response) => {
        return response.data
      })
      .catch(() => {
        goHome()
      })
  }

  const updateMainStreamManager = (stream) => {
    setStreamID(parseSubscriber(stream).id)
    setMainStreamManager(stream)
  }

  const deleteSubscriber = (streamManager) => {
    const localSubscribers = subscribers
    const index = localSubscribers.indexOf(streamManager, 0)
    if (index > -1) {
      localSubscribers.splice(index, 1)
      setSubscribers(localSubscribers.slice())
    }
  }

  const updateVideoDisplay = () => {
    if (subscribers.length === 1) {
      updateMainStreamManager(subscribers[0])
    } else {
      let found = false
      // filter out self video
      let localSubscribers = subscribers
      localSubscribers = localSubscribers.filter(
        (sub) => sub.stream?.connection?.data.lastName !== me.name[0]?.family
      )
      // first try to find a glassvu
      localSubscribers.forEach((ls) => {
        const userMetadata = JSON.parse(ls.stream?.connection?.data)
        const userType = userMetadata.userType
        if (userType === 'Device') {
          updateMainStreamManager(ls)
          found = true
        }
      })
      // if not found focus on first one
      if (!found) {
        updateMainStreamManager(localSubscribers[0])
      }
    }
  }

  React.useEffect(() => {
    if (subscribers.length > 0) {
      updateVideoDisplay()
    }
  }, [subscribers])

  const addSubscriber = (subscriber) => {
    const localSubscribers = subscribers
    localSubscribers.push(subscriber)
    setSubscribers(localSubscribers.slice())
  }

  const addChatMessage = (message) => {
    const localMessageArray = messageHistory
    localMessageArray.push(message)
    setMessageHistory(localMessageArray.slice())
  }

  const goHome = () => {
    navigate('/')
  }

  const handleConnectionDestroyed = (event) => {
    const userMetadata = JSON.parse(event.connection.data)
    const name = userMetadata.lastName
    if (event.reason === 'disconnect') {
      // user left by choice
      setSnackBarMessage(name + ' left the call')
      openSnackBar()
      if (getNumberOfParticipants() === 0) {
        endSession()
      }
    } else if (event.reason === 'networkDisconnect') {
      // user left due to network issue, wait to see if they rejoin
      setSnackBarMessage(name + ' lost their connection')
      openSnackBar()
      if (getNumberOfParticipants() === 0) {
        endSessionLater()
      }
    }
  }

  if (token == null) {
    return null
  }

  return (
    <VideoCall
      sendSignal={sendSignal}
      setSnackBarMessage={setSnackBarMessage}
      openSnackBar={openSnackBar}
      session={session}
      OV={OV}
      mySessionId={mySessionId}
      token={token}
      mainStreamManager={mainStreamManager}
      setMainStreamManager={setMainStreamManager}
      endSession={endSession}
      recordingMode={recordingMode}
      setRecordingMode={setRecordingMode}
      addChatMessage={addChatMessage}
      messageHistory={messageHistory}
      updateMainStreamManager={updateMainStreamManager}
      subscribers={subscribers}
      streamID={streamID}
    />
  )
}

MediaServer.propTypes = {
  mySessionId: PropTypes.string,
  online: PropTypes.object,
  addRemotevuToCall: PropTypes.func,
  addGlassvuToCall: PropTypes.func,
  setSnackBarMessage: PropTypes.func,
  setSnackBarMessageName: PropTypes.func,
  openSnackBar: PropTypes.func
}

export default React.memo(MediaServer)
