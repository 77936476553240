import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import WebIcon from '@mui/icons-material/Web'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import { MdLogout } from 'react-icons/md'

import { UserContext } from '@context/UserContext'
import { CurrentOrganizationContext } from '@context/CurrentOrganization'

import styles from './Sidebar.module.scss'

const Sidebar = () => {
  const { me, organizations } = useContext(UserContext)
  const { handleOrganizationChange, currentOrganization } = useContext(
    CurrentOrganizationContext
  )

  async function signOut () {
    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  useEffect(() => {
    if (organizations?.length > 0 && !currentOrganization) {
      handleOrganizationChange({ target: { value: organizations[0].id } })
    }
  }, [organizations, currentOrganization, handleOrganizationChange])

  return (
    <div className={styles.sidebar}>
      {process.env.REACT_APP_environment_type === 'clinicvu' && (
        <NavLink to="/patients">
          <img
            className={styles.sidebar__logo}
            src="/assets/clinicvu-logo.svg"
            alt="Logo"
          />
        </NavLink>
      )}
      {process.env.REACT_APP_environment_type === 'biovu' && (
        <img
          className={styles.sidebar__logo}
          src="/assets/biovu-logo.svg"
          alt="Logo"
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: 30
        }}
      >
        <div className={styles.sidebar__header}>
          Hello,{' '}
          {me?.name?.[0]?.given?.[0] || me?.name?.[0]?.family
            ? me?.name?.[0]?.given?.[0] + ' ' + me?.name?.[0]?.family
            : 'User!'}
        </div>
        {process.env.REACT_APP_environment_type === 'clinicvu'
          ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: 10 }}>Portal: </div>
            {me
              ? (
              <Select
                size="small"
                value={currentOrganization}
                onChange={handleOrganizationChange}
              >
                {organizations?.map((organization, i) => {
                  return (
                    <MenuItem key={i} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  )
                })}
              </Select>
                )
              : (
                  ''
                )}
          </div>
            )
          : null}
      </div>
      <NavLink
        className={({ isActive }) =>
          isActive ? styles['sidebar__item--active'] : styles.sidebar__item
        }
        to={
          process.env.REACT_APP_environment_type === 'clinicvu'
            ? '/patients'
            : '/users'
        }
      >
        <img
          className={styles['sidebar__item-icon']}
          src="/assets/svgs/home.svg"
          alt="Patients"
        />
        <span className={styles['sidebar__item-text']}>
          {process.env.REACT_APP_environment_type === 'clinicvu'
            ? 'Patients'
            : 'Users'}
        </span>
      </NavLink>
      {/* <NavLink
        className={styles["sidebar__item"]}
        to="/alerts"
        activeClassName={styles["sidebar__item--active"]}
      >
        <img
          className={styles["sidebar__item-icon"]}
          src="/assets/svgs/alert.svg"
          alt="Alerts"
        />
        <span className={styles["sidebar__item-text"]}>Alerts</span>
      </NavLink> */}
      {process.env.REACT_APP_environment_type === 'clinicvu'
        ? (
        <>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles['sidebar__item--active'] : styles.sidebar__item
            }
            to="/care-plans"
          >
            <img
              className={styles['sidebar__item-icon-dark']}
              src="/assets/svgs/care-plans.svg"
              alt="Care Plans"
            />
            <span className={styles['sidebar__item-text']}>Care Plans</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles['sidebar__item--active'] : styles.sidebar__item
            }
            to="/questionnaires"
          >
            <img
              className={styles['sidebar__item-icon-dark']}
              src="/assets/svgs/surveys.svg"
              alt="Questionnaires"
            />
            <span className={styles['sidebar__item-text']}>Questionnaires</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles['sidebar__item--active'] : styles.sidebar__item
            }
            to="/files"
          >
            <img
              className={styles['sidebar__item-icon-dark']}
              src="/assets/svgs/upload.svg"
              alt="Files"
            />
            <span className={styles['sidebar__item-text']}>Files</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles['sidebar__item--active'] : styles.sidebar__item
            }
            to="/clinic"
          >
            <img
              className={styles['sidebar__item-icon']}
              src="/assets/svgs/clinic.svg"
              alt="Clinic"
            />
            <span className={styles['sidebar__item-text']}>Clinic</span>
          </NavLink>
          {me?.is_admin
            ? (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? styles['sidebar__item--active']
                  : styles.sidebar__item
              }
              to="/portals"
            >
              <WebIcon
                className={styles['sidebar__item-icon']}
                style={{ fontSize: '15', color: 'gray' }}
              />
              <span className={styles['sidebar__item-text']}>Portal</span>
            </NavLink>
              )
            : (
                ''
              )}
        </>
          )
        : null}
      <button
        key={'SignOut'}
        onClick={signOut}
        className={`${styles.sidebar__item} ${styles.logout}`}
      >
        <MdLogout style={{ fontSize: 20, color: '#F50057' }} />
        <span className={styles['sidebar__item-text']}>Logout</span>
      </button>
    </div>
  )
}

Sidebar.propTypes = {
  currentPortal: PropTypes.number,
  handlePortalChange: PropTypes.func
}

export default Sidebar
