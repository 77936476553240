import { clinicvuInstance } from '../axiosService'

class ClinicvuService {
  async deleteCarePlan (carePlanId) {
    return clinicvuInstance.delete('/careplans/' + carePlanId)
  }

  async getCarePlans (organizationId) {
    return await clinicvuInstance.get(
      '/careplans/organization/' + organizationId
    )
  }

  async getCarePlan (carePlanId) {
    return await clinicvuInstance.get('/careplans/' + carePlanId)
  }

  async getOrganizationPractitioners (organizationId) {
    return await clinicvuInstance.get(
      '/practitioners/organization/' + organizationId
    )
  }

  async getPatientsOnOrganization (organizationId, searchTerm) {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      const params = new URLSearchParams()
      if (searchTerm) {
        params.append('search', searchTerm)
      }
      return await clinicvuInstance.get(
        `/patients/organization/${organizationId}`,
        { params }
      )
    } else {
      return await clinicvuInstance.get('/patients')
    }
  }

  async getPatient (patientId) {
    return await clinicvuInstance.get('/patients/' + patientId)
  }

  async getPatientECG (patientId, skipCount) {
    return await clinicvuInstance.get(
      `/patients/${patientId}/ecg?skipCount=${skipCount}`
    )
  }

  async notifyPatient (mode, patientId, messageRaw, messageHTML) {
    return await clinicvuInstance.post('/notifications/', {
      mode,
      patientId,
      messageRaw,
      messageHTML
    })
  }

  async reviewAlerts (patientId, practitionerId) {
    return await clinicvuInstance.post('/patients/review-alerts', {
      patientId,
      practitionerId
    })
  }

  async addNote (text, patientId, alertsReviewed) {
    return await clinicvuInstance.post('/patients/add-note', {
      text,
      patientId,
      alertsReviewed
    })
  }

  async getAllQuestionnaires (organizationId) {
    return await clinicvuInstance.get(
      '/questionnaires/organization/' + organizationId
    )
  }

  async deleteQuestionnaire (surveyId) {
    return await clinicvuInstance.delete('/questionnaires/' + surveyId)
  }

  async createCarePlan (name, requirements, organizationId) {
    return await clinicvuInstance.post('/careplans/', {
      name,
      requirements,
      organizationId
    })
  }

  async createPatient (data) {
    return await clinicvuInstance.post('/patients/', data)
  }

  async updatePatient (patientId, data) {
    return await clinicvuInstance.patch(`/patients/${patientId}`, data)
  }

  async createPractitioner (
    firstName,
    lastName,
    organizationId,
    email,
    role,
    selectedCarePlans
  ) {
    return await clinicvuInstance.post('/practitioners/', {
      firstName,
      lastName,
      organizationId,
      email,
      role,
      selectedCarePlans
    })
  }

  async updatePractitioner (practitionerId, selectedCarePlans) {
    return await clinicvuInstance.patch('/practitioners/' + practitionerId, {
      selectedCarePlans
    })
  }

  async getPractitioner (practitionerId) {
    return await clinicvuInstance.get('/practitioners/' + practitionerId)
  }

  async getQuestionnaire (questionnaireId) {
    return await clinicvuInstance.get(`/questionnaires/${questionnaireId}`)
  }

  async createQuestionnaire (organizationId, title, item) {
    return await clinicvuInstance.post('/questionnaires/', {
      organizationId,
      title,
      item
    })
  }

  async fetchMe () {
    if (process.env.REACT_APP_environment_type === 'clinicvu') {
      return await clinicvuInstance.get('/practitioners/whoami')
    } else {
      return await clinicvuInstance.get('/patients/whoami')
    }
  }

  async fetchAllOrganizations () {
    return await clinicvuInstance.get('/organizations')
  }

  async createOrganization (name, licenses, timezone) {
    return await clinicvuInstance.post('/organizations', {
      name,
      licenses,
      timezone
    })
  }

  async createAppointment (
    patientId,
    topic,
    description,
    date,
    fromTime,
    toTime
  ) {
    return await clinicvuInstance.post('/appointments', {
      patientId,
      topic,
      description,
      date,
      fromTime,
      toTime
    })
  }

  async joinSession (clientData, sessionName) {
    return await clinicvuInstance.post('/sessions', {
      clientData,
      sessionName
    })
  }

  async leaveSession (sessionName, token) {
    return await clinicvuInstance.post('/sessions/leave-session', {
      sessionName,
      token
    })
  }

  async getAllFiles (organizationId) {
    return await clinicvuInstance.get('/files/organization/' + organizationId)
  }

  async createFile (formData) {
    return await clinicvuInstance.post('/files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async getFile (fileId) {
    return await clinicvuInstance.get('/files/' + fileId)
  }
}

export default new ClinicvuService()
