import React, { useEffect } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { patientModalStyles } from '@utils/constants'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded'
import Looks4RoundedIcon from '@mui/icons-material/Looks4Rounded'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import styles from './NewAppointmentModal.module.scss'

const NewAppointmentModal = ({
  patient,
  isOpenAppointmentModal,
  setIsOpenAppointmentModal,
  me,
  scheduleAppointment
}) => {
  const [topic, setTopic] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [date, setDate] = React.useState(dayjs().add(1, 'day'))
  const [fromTime, setFromTime] = React.useState(
    dayjs()
      .add(1, 'day')
      .hour(dayjs().hour() + 1)
      .minute(0)
  )
  const [toTime, setToTime] = React.useState(
    dayjs()
      .add(1, 'day')
      .hour(dayjs().hour() + 2)
      .minute(0)
  )

  useEffect(() => {
    setFromTime(
      dayjs(date)
        .hour(dayjs().hour() + 1)
        .minute(0)
    )
    setToTime(
      dayjs(date)
        .hour(dayjs().hour() + 2)
        .minute(0)
    )
  }, [date])

  useEffect(() => {
    setToTime(fromTime.add(1, 'hour'))
  }, [fromTime])

  return (
    <Modal
      open={isOpenAppointmentModal}
      onClose={() => setIsOpenAppointmentModal(false)}
      aria-labelledby="Patient New Appointment Modal"
      aria-describedby="This modal allows the clinician to add appointments for the current patient"
    >
      <Box sx={patientModalStyles}>
        <Typography id="new-notes-modal__title">
          Schedule Appointment
        </Typography>
        <Typography id="new-notes-modal__sub" variant="h6" component="h2">
          {me?.name?.[0]?.family}, {me?.name?.[0]?.given[0]} |{' '}
          {patient?.name?.[0]?.family}
        </Typography>
        <hr />
        <div className={styles.modal__row}>
          <LooksOneRoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Description</Typography>
          <TextField
            id="filled-basic"
            label="Enter here"
            variant="filled"
            sx={{ width: '95%' }}
            onChange={(e) => setTopic(e.target.value)}
          />
        </div>
        <div className={styles.modal__row}>
          <LooksTwoRoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Comment (Optional)</Typography>
          <TextField
            id="filled-basic"
            label="Enter here"
            variant="filled"
            multiline
            rows={4}
            sx={{ width: '95%' }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.modal__row}>
          <Looks3RoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => (
                <TextField sx={{ width: '95%' }} {...props} />
              )}
              label="Appointment Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.modal__row}>
          <Looks4RoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>From</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              renderInput={(props) => (
                <TextField sx={{ width: '95%' }} {...props} />
              )}
              label="From Time"
              value={fromTime}
              onChange={(newValue) => {
                setFromTime(newValue)
              }}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.modal__row}>
          <Looks4RoundedIcon fontSize="large" />
          <Typography sx={{ width: '20%' }}>To</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              renderInput={(props) => (
                <TextField sx={{ width: '95%' }} {...props} />
              )}
              label="To Time"
              value={toTime}
              onChange={(newValue) => {
                setToTime(newValue)
              }}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsOpenAppointmentModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: 1 }}
            disabled={!topic || topic.length === 0}
            onClick={() =>
              scheduleAppointment(topic, description, date, fromTime, toTime)
            }
          >
            Schedule
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

NewAppointmentModal.propTypes = {
  isOpenAppointmentModal: PropTypes.bool,
  setIsOpenAppointmentModal: PropTypes.func,
  me: PropTypes.object,
  patient: PropTypes.object,
  scheduleAppointment: PropTypes.func
}

export default NewAppointmentModal
