import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewAppointmentModal = ({
  selectedAppointment,
  setSelectedAppointment,
  appointments
}) => {
  return (
    <Modal
      open={!!selectedAppointment}
      onClose={() => setSelectedAppointment(null)}
      aria-labelledby="Appointment Modal"
      aria-describedby="This modal contains the detailed information regarding the scheduled appointments"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Appointment Details
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedAppointment?.resource?.meta?.lastUpdated).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        <Typography>
          Description: {selectedAppointment?.resource?.description}
        </Typography>
        <Typography>
          Comment: {selectedAppointment?.resource?.comment}
        </Typography>
        <Typography>
          Date:{' '}
          {`${moment(selectedAppointment?.resource?.start).format('MMMM Do YYYY')}`}
        </Typography>
        <Typography>
          From:{' '}
          {`${moment(selectedAppointment?.resource?.start).format('h:mm a')}`}
        </Typography>
        <Typography>
          To: {`${moment(selectedAppointment?.resource?.end).format('h:mm a')}`}
        </Typography>
        <Typography>
          Practitioner:{' '}
          {selectedAppointment?.resource?.participant
            ?.filter((p) => p.actor.reference.includes('Practitioner'))
            ?.map((p) => {
              const participant = appointments.find(
                (appointment) =>
                  appointment.resource.id === p.actor.reference.split('/')[1]
              )
              const givenName =
                participant?.resource?.name?.[0]?.given?.[0] || 'NA'
              const familyName =
                participant?.resource?.name?.[0]?.family || 'NA'
              return `${givenName} ${familyName}`
            })
            .join(', ')}
        </Typography>
        {selectedAppointment?.resource?.extension?.some(
          (ext) =>
            ext.url === 'http://example.org/fhir/StructureDefinition/sessionId'
        ) && (
          <Typography sx={{ width: '90%' }}>
            Link:{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href={`/session?join_session_id=${
                selectedAppointment.resource.extension.find(
                  (ext) =>
                    ext.url ===
                    'http://example.org/fhir/StructureDefinition/sessionId'
                ).valueString
              }`}
            >
              Click Here
            </a>
          </Typography>
        )}
      </Box>
    </Modal>
  )
}

ViewAppointmentModal.propTypes = {
  selectedAppointment: PropTypes.object,
  setSelectedAppointment: PropTypes.func
}

export default ViewAppointmentModal
