// todo: add phone number to the object that represents our patients in the backend
// todo: re-add the edit and delete buttons with proper functionality.

import React, { useContext, useEffect, useState } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import styles from './AllPatientsTable.module.scss'
import { epochToDDMMYY, capitalize, getAlerts } from '@utils/functions'
import { CurrentOrganizationContext } from '@context/CurrentOrganization'
import { UserContext } from '@context/UserContext'
import { useNavigate } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import PatientAlerts from './patient-alerts/PatientAlerts'

const AllPatientsTable = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPatients, setCurrentPatients] = useState([])

  const navigate = useNavigate()

  const { currentOrganization } = useContext(CurrentOrganizationContext)
  const { me } = useContext(UserContext)

  const [patients, setPatients] = useState([])

  const fetchPatients = async () => {
    if (me) {
      try {
        const res = await ClinicvuService.getPatientsOnOrganization(
          currentOrganization,
          searchTerm
        )
        if (res.status !== 200) {
          throw new Error(
            `Was not able to fetch all patients on portal ${currentOrganization}`
          )
        }
        setPatients(res.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchPatients()
  }, [currentOrganization, searchTerm, me])

  useEffect(() => {
    setCurrentPatients(
      patients?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
        []
    )
  }, [patients, page, rowsPerPage])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.header} ${
          process.env.REACT_APP_environment_type === 'biovu'
            ? styles.header__top
            : ''
        }`}
      >
        {process.env.REACT_APP_environment_type === 'clinicvu'
          ? 'Patients'
          : 'Users'}
      </div>
      {process.env.REACT_APP_environment_type === 'clinicvu' && (
        <div className={styles['top-section__search']}>
          <img
            src="/assets/svgs/search.svg"
            alt="Search"
            className={styles['top-section__search-icon']}
          />
          <input
            type="text"
            placeholder="Search"
            className={styles['top-section__search-input']}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table
          stickyHeader
          aria-label="All Patients Table"
          className={styles.table}
        >
          <TableHead className={styles.table__head}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Sex</TableCell>
              {process.env.REACT_APP_environment_type === 'clinicvu'
                ? (
                <>
                  <TableCell>Alerts</TableCell>
                </>
                  )
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {process.env.REACT_APP_environment_type === 'clinicvu' &&
              currentPatients.map((patient, index) => (
                <TableRow
                  className={styles.tablerow}
                  key={patient.resource.id + '-' + index}
                >
                  <TableCell
                    onClick={() => navigate(`/patient/${patient.resource.id}`)}
                  >
                    <div className={styles.username}>
                      <img
                        src="assets/user-icon.jpeg"
                        alt={`User ${patient.resource.name[0].text}`}
                        className={styles['user-icon']}
                      />
                      <span className={styles.username__text}>
                        {patient.resource.name[0].given.join(' ') +
                          ' ' +
                          patient.resource.name[0].family}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/patient/${patient.resource.id}`)}
                  >
                    {epochToDDMMYY(
                      new Date(patient.resource.birthDate).getTime()
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/patient/${patient.resource.id}`)}
                  >
                    {capitalize(patient.resource.gender)}
                  </TableCell>
                  <TableCell>
                    <PatientAlerts
                      alerts={getAlerts(patient)}
                      patientId={patient.resource.id}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {process.env.REACT_APP_environment_type === 'biovu' &&
              currentPatients.map((patient, index) => (
                <TableRow
                  className={styles.tablerow}
                  key={patient.resource.id + '-' + index}
                  onClick={() => navigate(`/user/${patient.resource.id}`)}
                >
                  <TableCell>
                    <div className={styles.username}>
                      <img
                        src="assets/user-icon.jpeg"
                        alt={`User ${patient.resource.name[0].text}`}
                        className={styles['user-icon']}
                      />
                      <span className={styles.username__text}>
                        {patient.resource.name[0].given.join(' ') +
                          ' ' +
                          patient.resource.name[0].family}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {epochToDDMMYY(
                      new Date(patient.resource.birthDate).getTime()
                    )}
                  </TableCell>
                  <TableCell>{capitalize(patient.resource.gender)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                onPageChange={handlePageChange}
                count={patients?.length || 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AllPatientsTable
