/* eslint-disable */
// todo: refactor ECG logic.
// todo: refactor patient summary into a separate shared component.
// todo: so many states... what's the proper way of handling more complex structures like ours?
import EnrollNewPatientForm from '@shared/enroll-new-patient-form/EnrollNewPatientForm'
import { EmptyGraph } from '@utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import moment from 'moment'
import ContactForm from '@shared/contact-form/ContactForm'
import TopSection from '@shared/top-section/TopSection'
import {
  formatPhoneNumbers,
  generateDoubleLineGraphData,
  generateECGGraphData,
  generateSingleLineGraphData,
  generateSPO2GraphData,
  capitalize,
  epochToDDMMYY
} from '@utils/functions'
import styles from './PatientDetails.module.scss'
import EcgCanvas from './EcgCanvas'
import { UserContext } from '@context/UserContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Paper
} from '@mui/material'
import EditPatientModal from '@shared/edit-patient-modal/EditPatientModal'
import NewAppointmentModal from '@shared/new-appointment-modal/NewAppointmentModal'
import NewNotesModal from '@shared/new-notes-modal/NewNotesModal'
import ViewAlertModal from '@shared/view-alert-modal/ViewAlertModal'
import ViewAppointmentModal from '@shared/view-appointment-modal/ViewAppointmentModal'
import ViewCommunicationModal from '@shared/view-communication-modal/ViewCommunicationModal'
import ViewNoteModal from '@shared/view-note-modal/ViewNoteModal'
import ViewSurveyResponseModal from '@shared/view-survey-response-modal/ViewSurveyResponseModal'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
// TODO: figure out what "retake" is supposed to do.
// TODO: figure out the deal with days of the week and whether we are going to display them or not.
// TODO: What are the colors associated with each care plan?

const PatientDetails = () => {
  const [editPatient, setEditPatient] = useState(false)

  const [tempData, setTempData] = useState(EmptyGraph)
  const [weightData, setWeightData] = useState(EmptyGraph)
  const [bloodPressureData, setBloodPressureData] = useState(EmptyGraph)
  const [spo2Data, setSpo2Data] = useState(EmptyGraph)
  const [ecgData, setecgData] = useState(EmptyGraph)
  const [surveyGroups, setSurveyGroups] = useState(null)

  const [open, setOpen] = useState(false)

  const [patient, setPatient] = useState(null)
  const [ecg, setEcg] = useState(null)

  const [isOpenNotesModal, setIsOpenNotesModal] = useState(false)
  const [isOpenEditPatientModal, setIsOpenEditPatientModal] = useState(false)
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false)
  const [patientNoteText, setPatientNoteText] = useState('')
  const [patientIsReviewed, setPatientIsReviewed] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [selectedCommunication, setSelectedCommunication] = useState(null)

  const [selectedNote, setSelectedNote] = useState(null)
  const [selectedNotePractitioner, setSelectedNotePractitioner] = useState(null)
  const [selectedSurveyResponseGroup, setSelectedSurveyResponseGroup] =
    useState(null)

  const [ecgSkipCount, setEcgSkipCount] = useState(0)

  const location = useLocation()
  const [selectedAlert, setSelectedAlert] = useState(
    location.state?.alert || null
  )
  const { me } = useContext(UserContext)

  const navigate = useNavigate()

  const { patientID: id } = useParams()

  const closePatientNotesModal = () => {
    setPatientNoteText('')
    setPatientIsReviewed(null)
    setIsOpenNotesModal(false)
  }

  const fetchPatientData = async () => {
    try {
      const res = await ClinicvuService.getPatient(id)
      if (res.status !== 200) {
        throw new Error(`Was not able to fetch patient data with id: ${id}`)
      }
      console.log('communication', res.data)
      setPatient(res.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchPatientData()
  }, [id])

  // Function to get practitioner's name by reference
  const getPractitionerName = (reference) => {
    const practitioner = patient.compositions?.find(
      (composition) =>
        composition.resource.resourceType === 'Practitioner' &&
        composition.resource.id === reference.split('/')[1]
    )
    if (practitioner && practitioner.resource.name[0]) {
      const givenName = practitioner.resource.name[0].given?.[0] || 'NA'
      const familyName = practitioner.resource.name[0].family || 'NA'
      return `${givenName} ${familyName}`
    }
    return 'NA'
  }

  const fetchEcgData = async () => {
    try {
      const res = await ClinicvuService.getPatientECG(id, ecgSkipCount)
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch ECG data with skip count: ${ecgSkipCount}`
        )
      }
      setEcg(res.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchEcgData()
  }, [id, ecgSkipCount])

  useEffect(() => {
    if (patient && patient.observations) {
      const temperatureObservations = patient.observations.filter((obs) =>
        obs.resource.code.coding.some((coding) => coding.code === '8310-5')
      )
      const pulseOximetryObservations = patient.observations.filter((obs) =>
        obs.resource.code.coding.some((coding) => coding.code === '59408-5')
      )
      const bloodPressureObservations = patient.observations.filter((obs) =>
        obs.resource.code.coding.some((coding) => coding.code === '85354-9')
      )
      const weightObservations = patient.observations.filter((obs) =>
        obs.resource.code.coding.some((coding) => coding.code === '29463-7')
      )

      console.log('observations', patient.observations)
      console.log('weightObservations', weightObservations)

      if (temperatureObservations.length > 0) {
        setTempData(generateSingleLineGraphData(temperatureObservations))
      }

      if (weightObservations.length > 0) {
        setWeightData(generateSingleLineGraphData(weightObservations))
      }

      if (bloodPressureObservations.length > 0) {
        setBloodPressureData(
          generateDoubleLineGraphData(
            bloodPressureObservations,
            'Systollic',
            'Diastollic'
          )
        )
      }

      if (pulseOximetryObservations.length > 0) {
        setSpo2Data(
          generateSPO2GraphData(pulseOximetryObservations, '% Blood Oxygen')
        )
      }

      // if (patient.surveyResponses) {
      //   let responses = patient.surveyResponses.slice()
      //   responses = responses.reverse()
      //   setSurveyGroups(responses)
      // }
    }
  }, [patient])

  useEffect(() => {
    if (ecg?.length > 0) {
      setecgData(generateECGGraphData(ecg))
    } else {
      setecgData(EmptyGraph)
    }
  }, [ecg, editPatient])

  const notifyPatient = async (notification) => {
    try {
      await ClinicvuService.notifyPatient(
        notification.selectedCommunicationMethod,
        id,
        notification.stateRaw,
        notification.stateHTML
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleNoteSubmit = async () => {
    try {
      await ClinicvuService.addNote(patientNoteText, id, patientIsReviewed)
      setIsOpenNotesModal(false)
    } catch (e) {
      console.error(`Error occurred while updating patient alerts: ${e}`)
    }
  }

  const scheduleAppointment = async (
    topic,
    description,
    date,
    fromTime,
    toTime
  ) => {
    try {
      await ClinicvuService.createAppointment(
        id,
        topic,
        description,
        date,
        fromTime,
        toTime
      )
      setIsOpenAppointmentModal(false)
    } catch (e) {
      console.error(`Error occurred while updating patient alerts: ${e}`)
    }
  }
  return (
    <div className={styles.content}>
      {process.env.REACT_APP_environment_type === 'clinicvu' ? (
        <TopSection
          btnFunction={() => navigate('/patients')}
          showNew={true}
        ></TopSection>
      ) : null}
      {!editPatient ? (
        <>
          <div className={styles['grid-container']}>
            {patient && (
              <div className={styles.card}>
                <div className={styles.card__header}>
                  Patient Details{' '}
                  <Tooltip title="Edit details.">
                    <button
                      className={styles['edit-button']}
                      onClick={() => setIsOpenEditPatientModal(true)}
                    >
                      <img src="/assets/svgs/pencil.svg" alt="Edit" />
                    </button>
                  </Tooltip>
                </div>
                <div className={styles['card__content--details']}>
                  <TableContainer component={Paper} sx={{ width: '100%' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>
                            {`${
                              patient.name?.[0]?.given?.[0]
                                ? patient.name[0].given[0]
                                : 'Unspecified'
                            } 
                                      ${
                                        patient.name?.[0]?.family
                                          ? patient.name[0].family
                                          : 'Unspecified'
                                      }`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sex</TableCell>
                          <TableCell>
                            {patient.gender
                              ? capitalize(patient.gender)
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of Birth</TableCell>
                          <TableCell>
                            {patient.birthDate
                              ? `${epochToDDMMYY(
                                  patient.birthDate
                                )} (${moment().diff(
                                  patient.birthDate,
                                  'years'
                                )} years old)`
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            {patient.telecom?.find((t) => t.system === 'email')
                              ?.value
                              ? patient.telecom.find(
                                  (t) => t.system === 'email'
                                ).value
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>
                            {patient.telecom?.find((t) => t.system === 'phone')
                              ?.value
                              ? formatPhoneNumbers(
                                  patient.telecom.find(
                                    (t) => t.system === 'phone'
                                  ).value
                                )
                              : 'Unspecified'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Health Number</TableCell>
                          <TableCell>
                            {
                              patient?.identifier?.find(
                                (i) =>
                                  i.system ===
                                  'http://hospital.smarthealthit.org'
                              )?.value
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Height</TableCell>
                          <TableCell>
                            {
                              patient.observations
                                ?.filter(
                                  (obs) =>
                                    obs.resource.code.coding[0].system ===
                                      'http://loinc.org' &&
                                    obs.resource.code.coding[0].code ===
                                      '8302-2'
                                )
                                ?.sort(
                                  (a, b) =>
                                    new Date(b.effectiveDateTime) -
                                    new Date(a.effectiveDateTime)
                                )[0]?.resource?.valueQuantity?.value
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
            {patient &&
              patient.detectedIssues &&
              patient.detectedIssues.length !== 0 && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Alerts Summary</div>
                  <div className={styles['card__content--summary']}>
                    {patient.detectedIssues.map((issue, index) => (
                      <button
                        className={styles['pre-modal-btn']}
                        key={`summary-${index}`}
                        onClick={() => setSelectedAlert(issue)}
                      >
                        {issue.resource.detail} on{' '}
                        {moment(issue.resource.identifiedDateTime).format(
                          'DD-MMM'
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              )}

            {patient && patient.carePlans && (
              <div className={styles.card}>
                <div className={styles.card__header}>Care Plans</div>
                <div className={styles['card__content--summary']}>
                  {patient.carePlans.map((carePlan, index) => (
                    <div
                      key={`summary-${index}`}
                      className={styles['care-plan']}
                    >
                      <div
                        className={styles['care-plan__icon']}
                        style={{
                          backgroundColor: carePlan.color
                        }}
                      ></div>
                      <span className={styles['care-plan__text']}>
                        {carePlan.resource.title}
                      </span>
                    </div>
                  ))}
                  {process.env.REACT_APP_environment_type === 'clinicvu' && (
                    <button
                      className={styles.btn}
                      onClick={() => setEditPatient(true)}
                    >
                      Change patient plans
                    </button>
                  )}
                </div>
              </div>
            )}

            {patient &&
              process.env.REACT_APP_environment_type === 'clinicvu' && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Administration</div>
                  <div className={styles['card__content--contact']}>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setOpen(true)}
                    >
                      Contact Patient
                    </button>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setIsOpenNotesModal(true)}
                    >
                      Add Note
                    </button>
                    <button
                      className={styles['btn--primary']}
                      onClick={() => setIsOpenAppointmentModal(true)}
                    >
                      Schedule Appointment
                    </button>
                  </div>
                </div>
              )}
            {patient && tempData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Temperature</div>
                <div className={styles.card__content}>
                  <Line data={tempData} options={{ responsive: true }} />
                </div>
              </div>
            )}
            {patient && bloodPressureData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Blood Pressure</div>
                <div className={styles.card__content}>
                  <Line
                    data={bloodPressureData}
                    options={{ responsive: true }}
                  />
                </div>
              </div>
            )}
            {patient && spo2Data.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>SpO2</div>
                <div className={styles.card__content}>
                  <Bar data={spo2Data} options={{ responsive: true }} />
                </div>
              </div>
            )}

            {patient && weightData.labels.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Weight</div>
                <div className={styles.card__content}>
                  <Line data={weightData} options={{ responsive: true }} />
                </div>
              </div>
            )}

            {patient &&
              patient.compositions &&
              patient.compositions.length !== 0 && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Notes</div>
                  <div className={styles['card__content--summary']}>
                    {patient.compositions
                      ?.filter(
                        (composition) =>
                          composition.resource.resourceType === 'Composition'
                      )
                      ?.sort(
                        (a, b) =>
                          new Date(b.resource.date) - new Date(a.resource.date)
                      )
                      ?.map((composition, index) => (
                        <button
                          className={styles['pre-modal-btn']}
                          key={`composition-${index}`}
                          onClick={() => {
                            setSelectedNote(composition)
                            setSelectedNotePractitioner(
                              getPractitionerName(
                                composition.resource.author[0].reference
                              )
                            )
                          }}
                        >{`${getPractitionerName(
                          composition.resource.author[0].reference
                        )} - ${moment(composition.resource.date).format(
                          'MMM Do YYYY, h:mm a'
                        )}`}</button>
                      ))}
                  </div>
                </div>
              )}

            {patient && surveyGroups && surveyGroups.length !== 0 && (
              <div className={styles.card}>
                <div className={styles.card__header}>Survey Responses</div>
                <div className={styles['card__content--summary']}>
                  {surveyGroups?.map((surveyResponseGroup, index) => (
                    <button
                      className={styles['pre-modal-btn']}
                      key={`survey-response-${index}`}
                      onClick={() =>
                        setSelectedSurveyResponseGroup(surveyResponseGroup)
                      }
                    >{`${surveyResponseGroup?.survey?.name || 'NA'} - ${moment(
                      surveyResponseGroup?.created_at
                    ).format('MMM Do YY, h:mm a')}`}</button>
                  ))}
                </div>
              </div>
            )}
            {patient &&
              patient.appointments &&
              patient.appointments.length !== 0 && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Appointments</div>
                  <div className={styles['card__content--summary']}>
                    {patient.appointments
                      .filter(
                        (appointment) =>
                          appointment.resource.resourceType === 'Appointment'
                      )
                      .sort((a, b) =>
                        a.resource.start > b.resource.start ? 1 : -1
                      )
                      .map((patientAppointment, index) => (
                        <button
                          className={styles['pre-modal-btn']}
                          key={`appointment-${index}`}
                          onClick={() =>
                            setSelectedAppointment(patientAppointment)
                          }
                        >{`${patientAppointment?.resource.description || 'NA'} - ${moment(
                          patientAppointment?.resource.start
                        ).format('MMM Do YY, h:mm a')} - ${moment(
                          patientAppointment?.resource.end
                        ).format('h:mm a')}`}</button>
                      ))}
                  </div>
                </div>
              )}

            {patient &&
              patient.communication &&
              patient.communication.length !== 0 && (
                <div className={styles.card}>
                  <div className={styles.card__header}>Communication</div>
                  <div className={styles['card__content--summary']}>
                    {patient.communication
                      .filter(
                        (communication) =>
                          communication.resource.resourceType ===
                          'Communication'
                      )
                      .sort((a, b) =>
                        a.resource.sent > b.resource.sent ? 1 : -1
                      )
                      .map((communication, index) => {
                        const isSent =
                          communication.resource.sender?.reference.includes(
                            'Practitioner'
                          )
                        const label = isSent ? 'Sent' : 'Received'
                        return (
                          <button
                            className={styles['pre-modal-btn']}
                            key={`communication-${index}`}
                            onClick={() =>
                              setSelectedCommunication(communication)
                            }
                          >{`${label} - ${moment(
                            communication?.resource.sent
                          ).format('MMM Do YY, h:mm a')}`}</button>
                        )
                      })}
                  </div>
                </div>
              )}
          </div>
          {patient && ecgData && Object.keys(ecgData).length > 2 && (
            <div className={`${styles.card} ${styles.ecg}`}>
              <div>
                <button
                  className={styles['btn--primary']}
                  disabled={ecgSkipCount === patient?.ecgCount - 1}
                  onClick={() => setEcgSkipCount(ecgSkipCount + 1)}
                >
                  Previous
                </button>
                <button
                  className={styles['btn--primary']}
                  disabled={ecgSkipCount === 0}
                  onClick={() => setEcgSkipCount(ecgSkipCount - 1)}
                >
                  Next
                </button>
              </div>
              {ecg?.length > 0 && (
                <div className={styles.card__header}>
                  ECG from{' '}
                  {moment(ecg[0]?.taken_at).format('MMMM Do YYYY, h:mm a')}
                </div>
              )}
              <div className={styles.card__content}>
                <EcgCanvas ecgData={ecgData} />
              </div>
            </div>
          )}
        </>
      ) : (
        <EnrollNewPatientForm
          setShowNew={setEditPatient}
          goBack={() => setEditPatient(false)}
          currPatient={patient}
        />
      )}

      <ContactForm
        open={open}
        setOpen={setOpen}
        notifyPatient={notifyPatient}
      />

      <NewNotesModal
        isOpenNotesModal={isOpenNotesModal}
        setIsOpenNotesModal={setIsOpenNotesModal}
        me={me}
        patientNoteText={patientNoteText}
        patientIsReviewed={patientIsReviewed}
        setPatientIsReviewed={setPatientIsReviewed}
        handleNoteSubmit={handleNoteSubmit}
        closePatientNotesModal={closePatientNotesModal}
        setPatientNoteText={setPatientNoteText}
      />

      <ViewNoteModal
        selectedNote={selectedNote}
        selectedNotePractitioner={selectedNotePractitioner}
        setSelectedNote={setSelectedNote}
      />

      <ViewSurveyResponseModal
        selectedSurveyResponseGroup={selectedSurveyResponseGroup}
        setSelectedSurveyResponseGroup={setSelectedSurveyResponseGroup}
      />

      <ViewAlertModal
        selectedAlert={selectedAlert}
        setSelectedAlert={setSelectedAlert}
      />

      <ViewAppointmentModal
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        appointments={patient?.appointments}
      />

      <ViewCommunicationModal
        selectedCommunication={selectedCommunication}
        setSelectedCommunication={setSelectedCommunication}
        communication={patient?.communication}
      />

      <NewAppointmentModal
        isOpenAppointmentModal={isOpenAppointmentModal}
        setIsOpenAppointmentModal={setIsOpenAppointmentModal}
        me={me}
        patient={patient}
        scheduleAppointment={scheduleAppointment}
      />

      <EditPatientModal
        isOpen={isOpenEditPatientModal}
        setIsOpenEditPatientModal={setIsOpenEditPatientModal}
        patientId={patient?.id}
        initialFirstName={patient?.name?.[0]?.given?.[0]}
        initialLastName={patient?.name?.[0]?.family}
        initialSex={patient?.gender}
        initialDOB={patient?.birthDate}
        initialEmail={
          patient?.telecom?.find((t) => t.system === 'email')?.value
        }
        initialPhone={
          patient?.telecom?.find((t) => t.system === 'phone')?.value
        }
        initialHealthNumber={
          patient?.identifier?.find(
            (i) => i.system === 'http://hospital.smarthealthit.org'
          )?.value
        }
        initialHeight={
          patient?.observations
            ?.filter(
              (obs) =>
                obs.resource.code.coding[0].system === 'http://loinc.org' &&
                obs.resource.code.coding[0].code === '8302-2'
            )
            ?.sort(
              (a, b) =>
                new Date(b.effectiveDateTime) - new Date(a.effectiveDateTime)
            )[0]?.resource?.valueQuantity?.value
        }
      />
    </div>
  )
}

export default PatientDetails
