import {
  Alert,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Box,
  Typography,
  Checkbox
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ParamGender } from '@utils/constants'
import React, { useContext, useState, useEffect } from 'react'
import styles from './EnrollNewPatientForm.module.scss'

import { CurrentOrganizationContext } from '@context/CurrentOrganization'
import PropTypes from 'prop-types'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { isValidEmail, isValidNumber } from 'utils/functions'

const confirmationModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const EnrollNewPatientForm = ({ setShowNew, goBack, currPatient }) => {
  // Patient Information Data

  const [email, setEmail] = useState(
    currPatient?.telecom?.find((t) => t.system === 'email')?.value || ''
  )
  const [firstName, setFirstName] = useState(
    currPatient?.name?.[0]?.given?.[0] || ''
  )
  const [lastName, setLastName] = useState(currPatient?.name?.[0]?.family || '')
  const [dateOfBirth, setDateOfBirth] = useState(
    currPatient?.birthDate ? new Date(currPatient.birthDate) : null
  )
  const [sex, setSex] = useState(currPatient?.gender || '')
  const [height, setHeight] = useState(
    currPatient?.extension?.find(
      (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/bodyheight'
    )?.valueQuantity?.value || ''
  )
  const [phoneNumber, setPhoneNumber] = useState(
    currPatient?.telecom?.find((t) => t.system === 'phone')?.value || ''
  )
  const [healthNumber, setHealthNumber] = useState(
    currPatient?.identifier?.find(
      (id) => id.system === 'http://hl7.org/fhir/sid/us-mrn'
    )?.value || ''
  )
  const [selectedCarePlans, setSelectedCarePlans] = useState(
    currPatient?.carePlans?.map((carePlan) => carePlan.resource.id) || []
  )
  const [carePlanToBeUnselected, setCarePlanToBeUnselected] = useState(null)

  const [emailInvalid, setEmailInvalid] = useState(false)
  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [lastNameInvalid, setLastNameInvalid] = useState(false)
  const [sexInvalid, setSexInvalid] = useState(false)
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false)
  const [dateOfBirthInvalid, setDateOfBirthInvalid] = useState(false)
  const [attempted, setAttempted] = useState(false)
  const [isConsented, setIsConsented] = React.useState(!!currPatient)
  const { currentOrganization } = useContext(CurrentOrganizationContext)

  const [carePlansOnPortal, setCarePlansOnPortal] = useState(null)

  const fetchCarePlans = async () => {
    if (currentOrganization) {
      try {
        const res = await ClinicvuService.getCarePlans(currentOrganization)
        if (res.status !== 200) {
          throw new Error(
            `Was not able to fetch careplans data for portal ${currentOrganization}`
          )
        }
        setCarePlansOnPortal(res.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchCarePlans()
  }, [currentOrganization])

  useEffect(() => {
    // Attempted allows us to only flag empty fields after an attempted submit.
    if (attempted) {
      setFirstNameInvalid(firstName === '')
      setLastNameInvalid(lastName === '')
      setSexInvalid(sex === '')
      setEmailInvalid(!isValidEmail(email))
      setDateOfBirthInvalid(!dateOfBirth)
      setPhoneNumberInvalid(!isValidNumber(phoneNumber))
    }
  }, [firstName, lastName, sex, email, attempted, phoneNumber, dateOfBirth])

  const saveUserInfo = async (e) => {
    e.preventDefault()
    setAttempted(true)
    if (
      isValidEmail(email) &&
      firstName !== '' &&
      lastName !== '' &&
      sex !== '' &&
      selectedCarePlans.length > 0 &&
      isValidNumber(phoneNumber) &&
      dateOfBirth
    ) {
      const patientData = {
        email,
        phoneNumber,
        healthNumber,
        selectedCarePlans,
        organizationId: currentOrganization,
        firstName,
        lastName,
        dateOfBirth: dateOfBirth.toISOString().split('T')[0], // Convert dateOfBirth to ISO string
        sex,
        height
      }

      try {
        const res = currPatient
          ? await ClinicvuService.updatePatient(currPatient.id, {
            selectedCarePlans
          })
          : await ClinicvuService.createPatient(patientData)

        if (res.status !== 200) {
          throw new Error('Was unable to save user information with id')
        }
        setShowNew(false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleToggleCarePlan = (carePlan) => {
    if (selectedCarePlans.includes(carePlan.id)) {
      setCarePlanToBeUnselected(carePlan)
    } else {
      const newSelectedCarePlans = [...selectedCarePlans, carePlan.id]
      setSelectedCarePlans(newSelectedCarePlans)
    }
  }

  const unselectCarePlan = (carePlan) => {
    const carePlanIndex = selectedCarePlans.indexOf(carePlan.id)
    const newSelectedCarePlans = [...selectedCarePlans]
    newSelectedCarePlans.splice(carePlanIndex, 1)
    setSelectedCarePlans(newSelectedCarePlans)
    setCarePlanToBeUnselected(null)
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.header}>New Patient</div>
        <form className={styles['enrollment-form']}>
          {/* Patient Info */}
          <div className={styles['info-container']}>
            <div className={styles['info-container__row']}>
              <TextField
                label="Email*"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.email}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPatient}
                error={emailInvalid}
                helperText={emailInvalid ? 'Enter a valid email' : ''}
              />

              <TextField
                label="Phone Number (+14166288463)"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={styles['phone-number']}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPatient}
                error={phoneNumberInvalid}
                helperText={
                  phoneNumberInvalid ? 'Enter a valid phone number' : ''
                }
              />

              <TextField
                label="Patient Health Number"
                variant="outlined"
                value={healthNumber}
                onChange={(e) => setHealthNumber(e.target.value)}
                className={styles['phone-number']}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPatient}
              />

              <TextField
                label="Height (cm)"
                InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                variant="outlined"
                type="number"
                placeholder="Height (cm)"
                value={height}
                onInput={(e) => setHeight(Number(e.target.value))}
                size="small"
                className={styles.height}
                style={{ marginLeft: '15px' }}
                disabled={!!currPatient}
              />
            </div>
            <div className={styles['info-container__row']}>
              <TextField
                label="First Name*"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={styles['first-last-name']}
                size="small"
                style={{ marginLeft: '15px' }}
                disabled={!!currPatient}
                error={firstNameInvalid}
                helperText={firstNameInvalid ? 'First name is required' : ''}
              />

              <TextField
                label="Last Name*"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={styles['first-last-name']}
                size="small"
                style={{ marginLeft: '15px', marginRight: '15px' }}
                disabled={!!currPatient}
                error={lastNameInvalid}
                helperText={lastNameInvalid ? 'Last name is required' : ''}
              />
              <DesktopDatePicker
                label="Date of Birth *"
                inputFormat="DD/MM/YYYY"
                className={styles['date-of-birth']}
                value={dateOfBirth}
                onChange={(date) => setDateOfBirth(date)}
                disabled={!!currPatient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={dateOfBirthInvalid}
                    helperText={
                      dateOfBirthInvalid ? 'Date of birth is required' : ''
                    }
                  />
                )}
                disableFuture={true}
              />
              <TextField
                label="Sex *"
                value={sex}
                onChange={(e) => setSex(e.target.value)}
                className={styles['date-of-birth']}
                disabled={!!currPatient}
                error={sexInvalid}
                sx={{ marginLeft: '15px' }}
                select
              >
                {Object.keys(ParamGender).map((gender) => {
                  return (
                    <MenuItem key={gender} value={gender}>
                      {ParamGender[gender]}
                    </MenuItem>
                  )
                })}
              </TextField>
            </div>
          </div>

          <div className={styles['info-container']}>
            <div className={styles['info-container__care-header']}>
              Care Plans
            </div>
            {selectedCarePlans.length === 0 && attempted && (
              <Alert severity="warning">
                At least one care plan must be selected
              </Alert>
            )}
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {carePlansOnPortal?.map((carePlan, index) => {
                const labelId = `care-plan-${carePlan?.name}`

                return (
                  <ListItem key={index} disablePadding sx={{ height: '35px' }}>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleToggleCarePlan(carePlan)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedCarePlans.includes(carePlan.id)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          sx={{ padding: 0 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={`${carePlan?.title}`}
                        sx={{ marginLeft: '-30px' }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
            <div
              className={`${styles['info-container__row']} ${styles['btns-row']}`}
            >
              <div className={styles.consent}>
                <Checkbox
                  checked={isConsented}
                  onChange={(event) => setIsConsented(event.target.checked)}
                />
                <p>Patient has consented to be enrolled into the program *</p>
              </div>
              <div>
                <button
                  onClick={goBack}
                  className={`${styles['enrollment-form__btn']} ${styles.cancel}`}
                >
                  Cancel
                </button>
                <button
                  onClick={saveUserInfo}
                  type="submit"
                  className={styles['enrollment-form__btn']}
                  disabled={!isConsented}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <Modal
            open={!!carePlanToBeUnselected}
            onClose={() => setCarePlanToBeUnselected(null)}
            aria-labelledby="careplans-confirmation-modal"
            aria-describedby="Confirm unselecting a care plan"
          >
            <Box sx={confirmationModalStyle}>
              <Typography variant="h6" component="h2">
                Are you sure?
              </Typography>
              <Typography>
                Unselecting the {`${carePlanToBeUnselected?.name}`} care plan
                will dissociate the patient with all the requirements of the
                care plan and its clinical team.
              </Typography>
              <div className={`${styles.row} ${styles['row--spaced']}`}>
                <button
                  className={styles.btn}
                  onClick={() => setCarePlanToBeUnselected(null)}
                >
                  Cancel
                </button>
                <button
                  className={`${styles.btn} ${styles['btn--red']}`}
                  onClick={() => unselectCarePlan(carePlanToBeUnselected)}
                >
                  Unselect Care Plan
                </button>
              </div>
            </Box>
          </Modal>
        </form>
      </LocalizationProvider>
    </div>
  )
}

EnrollNewPatientForm.propTypes = {
  setShowNew: PropTypes.func,
  goBack: PropTypes.func,
  currPatient: PropTypes.object
}

export default EnrollNewPatientForm
