import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewNoteModal = ({
  selectedNote,
  selectedNotePractitioner,
  setSelectedNote
}) => {
  return (
    <Modal
      open={!!selectedNote}
      onClose={() => setSelectedNote(null)}
      aria-labelledby="Patient Note Details Modal"
      aria-describedby="This modal contains the detailed information regarding the currently selected note"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Note Details
        </Typography>
        <Typography
          id="note-details-modal__sub-clinician"
          variant="h6"
          component="h2"
        >
          Created by: {`${selectedNotePractitioner || 'NA'}`}
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedNote?.resource?.date).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        {selectedNote?.resource?.section?.map((section, index) => (
          <div key={index}>
            <Typography
              sx={{
                whiteSpace: 'pre-wrap',
                overflow: 'scroll',
                maxHeight: 500
              }}
              dangerouslySetInnerHTML={{ __html: section.text.div }}
            />
            <hr />
          </div>
        ))}
      </Box>
    </Modal>
  )
}

ViewNoteModal.propTypes = {
  selectedNote: PropTypes.object,
  setSelectedNote: PropTypes.func
}

export default ViewNoteModal
